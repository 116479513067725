import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { Firestore } from '@angular/fire/firestore';
import { accountScopeSchema, contactSchema } from '../../core/account.types';
import { locationSchema, LocationWithCoords } from '../../core/location';
import { filter, take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { selectCurrentUser } from '../../current-user/store/current-user.selectors';
import { inputIsNotNullOrUndefined } from '../../data-access/input-is-not-null-or-undefined';
import { getRelatedUserIds$ } from '../../data-access/users';
import { getAccounts$, getAccountsForUserIds$, getAllCompanyAccounts$, } from '../../data-access/accounts';
import { isArray, isString, omit } from 'lodash';
import { UserTypes } from '../../users/user.types';
import { fromUnixTime, isPast } from "date-fns";
import { accountCategorySchema } from "../../data-access/account-category";
import { z } from 'zod';
import { phoneSchema } from '../../models/phone';
import { handleSchema } from '../../models/handle-schema';

const accountSchema = z.object({
  name: z.string(),
  scope: accountScopeSchema,
  parentIds: z.optional(z.nullable(z.array(z.string()))),
  users: z.optional(z.object({
    ids: z.array(z.string()),
    list: z.record(z.string(), z.object({
      displayName: z.string(),
      email: z.string(),
      photoURL: z.nullable(z.string()).default(null),
    })),
  })),
  accountType: z.optional(z.nullable(accountCategorySchema.and(z.object({ id: z.string() })))),
  accountGoal: z.string(),
  website: z.string(),
  phone: phoneSchema,
  location: locationSchema,
  contacts: z.array(contactSchema),
  email: z.optional(z.string()),
  tags: z.optional(z.array(z.string())),
  completedStop: z.optional(z.number()),
  nextVisit: z.optional(z.number()),
  createdAt: z.optional(z.number()),
  createdById: z.optional(z.string()),
  updatedAt: z.optional(z.number()),
  updatedById: z.optional(z.string()),
  source: z.optional(z.string()),
  reference: z.optional(z.string()),
  activatedAt: z.optional(z.nullable(z.number())),
});

export interface AccountUsers {
  ids: string[],
  list: {
    [userId: string]: {
      displayName: string,
      email: string,
      photoURL: string | null,
    }
  },
}

type AccountSchema = z.infer<typeof accountSchema>;
export type Account = AccountSchema & { id: string };

export interface AccountWithCoords extends Account {
  location: LocationWithCoords,
}

export const isAccountActivated = (account: Account): boolean => {
  return !account.activatedAt || (account.activatedAt !== Number.MAX_SAFE_INTEGER && isPast(fromUnixTime(account.activatedAt)));
};

export const isAccountWithCoords = (account: Account): account is AccountWithCoords => {
  return !!(account.location.lat && account.location.lng);
};

export const accountUserListObjToArray = (users: Account['users']) => {
  return Object.entries(users?.list || {}).map(([ key, value ]) => ({
    id: key,
    ...omit(value, 'photoUrl'),
  }));
};

@Injectable({
  providedIn: 'root',
})
export class GetAccountsService {
  constructor(private firestore: Firestore, private store$: Store) {
  }

  get(userId?: string | string[]): Observable<Account[]> {
    return this.store$.pipe(
      select(selectCurrentUser),
      filter(inputIsNotNullOrUndefined),
      take(1),
      switchMap(currentUser => {
        const companyId = currentUser.accountId;

        // If current user is owner of company and userId is not specified - return all accounts of company
        if (!userId && currentUser.type === UserTypes.OWNER) {
          return getAllCompanyAccounts$(this.firestore, currentUser.accountId).pipe(
            handleSchema(accountSchema, currentUser),
          );
        }

        // Todo: Remove get accounts by id or ids as we always gets it related or company scope?
        // Get for single user
        if (isString(userId)) {
          return getAccounts$(this.firestore, companyId, userId).pipe(
            handleSchema(accountSchema, currentUser),
          );
        }

        // Get for multiple users
        if (isArray(userId)) {
          return getAccountsForUserIds$(this.firestore, companyId, userId).pipe(
            handleSchema(accountSchema, currentUser),
          );
        }

        // Get all related users
        return getRelatedUserIds$(this.firestore, currentUser).pipe(
          switchMap(userIds => getAccountsForUserIds$(this.firestore, companyId, userIds)),
          handleSchema(accountSchema, currentUser),
        );
      }),
    );
  }
}
